/*
 * Couleurs
 * ----------------------------------------------------------------------------*/
$firstColor: #22ae8a; // Couleur principale du site : titre principal, fonds...
$secondColor: #ffab37; // Couleur secondaire du site : titres, menus...
$menuColor: #4d7a75; // Couleur de fond du menu
$menuTextColor: #FFF; // Couleur de texte du menu
$bgColor: #444; // Couleur de fond sur le site
$textColor: #6d6d6d; // Couleur par défaut des textes
$fondContenu: #F1F5F7;
$borderColor: #e5e5e5;
$footerColor: #8e929d;

$cf7_valid: #68bb4c;
$cf7_error: #FF6666;

$adminColor: #002a37; // Bleu foncé
$adminColor2: #003f50; // Bleu plus clair
$adminColor3: #ffab37; // Orange
