/*
	FONTS - Feuille de styles
	Author: Nico Blandel | @NicoBlandel
	Author URI: www.nicoblandel.fr
*/

// ESSAIS SVG
.icons_hide {
	display: none;
}
.icon {
  display: inline-block;
  width: 25px;
  height: 25px;

	text {
		display: none;
	}
}
