/*
 * Fonts
 * ----------------------------------------------------------------------------*/
$font-base: 'Montserrat'; // 'Helvetica', sans-serif;

/*
 * Variables / tailles
 * ----------------------------------------------------------------------------*/
$typosize: 16px;
$header_height: 100px;
$header_height_mobile: 65px;
